
import React from 'react'
import './DetailPage.css'
import './PreviewPage.scss'
import { Link } from "react-router-dom";
import { FaLinkedin } from "@react-icons/all-files/fa/FaLinkedin";
import { FaFacebookSquare, FaYoutubeSquare, FaInstagramSquare } from "react-icons/fa";
import {
  homeClick,
  underlineMenuAbout,
  underlineMenuContact,
  underlineMenuPortfolio,
} from "./script/checkUnderline";
import './PreviewPage.scss'; // Import your SCSS file for styling
import { useEffect } from 'react';
import { FaSquareXTwitter } from "react-icons/fa6";
import { Helmet } from "react-helmet";


const iconImage = require("./script/test-img/redlightcam-icon.JPEG");
// const appleImage = require("./script/test-img/apple.png");

const screenShot1 = require("./script/test-img/redlightcam-1.jpg");
const screenShot2 = require("./script/test-img/redlightcam-2.jpg");
const screenShot3 = require("./script/test-img/redlightcam-3.jpg");

export function useTitle(title) {

  useEffect(() => {
    const prevTitle = window.document.title
    window.document.title = title

    return () => {
      window.document.title = prevTitle
      console.log(`window title: ${window.document.title}`)
      document.getElementById("gamesMenuText").style.borderBottom = "solid 2px";
      document.getElementById("gamesMenuText").style.borderColor = "red";

    }

  })
}






const RedLightCamDetailPage = () => {
  useTitle("redlightcam - THE RISE COLLECTION")



  return (



    <div className="creative-portfolio" style={{ minHeight: '100vh', background: '#ffffff' }}>
      <Helmet>
        <title>redlightcam - THE RISE COLLECTION</title>
        <meta
          name="description"
          content="Through redlightcam we capture the local car scene like you are on the ground with us. With capturing build of all kinds + meeting business owners within the space our mission is to highlight talent that might've been missed otherwise."
        />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <div className="flex-box-header header" id="detail">
        <div className="headerText">
          <a href="/">
            <h2 style={{ fontSize: "30px" }}>THE RISE COLLECTION</h2>
          </a>
        </div>
        <div className="menuItems">
        <Link to="/" onClick={homeClick}>
            <p id="homeMenuText">HOME</p>
          </Link>
          <Link to="/#about-us" onClick={underlineMenuAbout}>
            <p id="aboutUsMenuText">ABOUT US</p>
          </Link>
          <Link to="/#portfolio" onClick={underlineMenuPortfolio}>
            <p style={{ borderBottom: 'solid 2px', borderColor: 'red' }} id="gamesMenuText">PORTFOLIO</p>
          </Link>
          <Link to="/#contact-us" onClick={underlineMenuContact}>
            <p id="mediaMenuText">CONTACT US</p>
          </Link>
        </div>
      </div>

      <div className="app-container">
        <div className="game-info">
          <img src={iconImage} alt="Game Icon" className="game-icon" />
          <div className="game-details">
            <h2 style={{ fontSize: "18px" }}>REDLIGHTCAM</h2>
            <p style={{ fontSize: '16px', color: 'red', fontWeight: 'bold' }}>THE RISE COLLECTION</p>
            <div className='tag-container'>
              <div className='right-tag' style={{ marginRight: '5px', background: 'black', textAlign: 'center', height: '20px', color: 'white', borderRadius: '5px', width: '65px', paddingTop: '0.98px' }}>BRAND</div>
            </div>
          </div>
        </div>
        <hr />
        <div style={{ marginBottom: '20px' }}>
          <h2>About the Brand</h2>
          <p style={{ fontSize: '18px' }}>
          At redlightcam, we provide an immersive perspective of the local car scene, allowing you to experience it as if you were "on the ground" with us. Our mission is to document a diverse range of automotive builds and engage with business owners in the industry, showcasing talent that may otherwise go unnoticed.
          </p>
          <hr />
        </div>
        <h2>Examples</h2>
        <div className="screenshot-container-web">
          <img src={screenShot1} alt='REDLIGHTCAM_SCREENSHOT_1' />
          <img src={screenShot2} alt='REDLIGHTCAM_SCREENSHOT_2' />
          <img src={screenShot3} alt='REDLIGHTCAM_SCREENSHOT_3' />
        </div>
        <hr />
        <div style={{ fontSize: '18px' }}>
          <h2 style={{ fontSize: '24px' }}>Additional Information</h2>
          <p style={{ fontSize: '18px' }}>
            Find content associated to this project linked below:
          </p>
          <ul style={{ fontSize: '18px', paddingBottom: '25px' }}>
            <li><a href="https://www.instagram.com/redlightcam/?hl=en"
              style={{ color: "red", }}>Instagram</a></li>
            <li><a href="https://www.youtube.com/channel/UCAxztMJo0zzVGyepmDsuGnw?app=desktop"
              style={{ color: "red", }}>YouTube</a></li>
            <li><a href="https://www.facebook.com/p/redlightcam-100086354563813/"
              style={{ color: "red", }}>Facebook</a></li>

          </ul>
        </div>
      </div>
      <footer className="footer" id="detail-footer">
        <div className="footer-content">
          <div className="footer-social">
            <a href="https://www.instagram.com/rwtrcsc/" aria-label="instagram-link">
              <FaInstagramSquare
                style={{
                  color: "white",
                  height: "35px",
                  width: "25px",
                  marginRight: "15px",
                }}
              />
            </a>

            <a href="https://www.facebook.com/rwtrcsc/" aria-label="facebook-link">
              <FaFacebookSquare
                style={{
                  color: "white",
                  height: "35px",
                  width: "25px",
                  marginRight: "15px",
                }}
              />
            </a>
            <a href="https://twitter.com/rwtrcsc">
              <FaSquareXTwitter FaSquareXTwitter style={{
                color: "white",
                height: "35px",
                width: "25px",
                marginRight: "15px",
              }}></FaSquareXTwitter >

            </a>

            <a href="https://www.youtube.com/channel/UCKbCkAn_nig1WaweOc7kl7Q" aria-label="youtube-link">
              <FaYoutubeSquare
                style={{
                  color: "white",
                  height: "35px",
                  width: "25px",
                  marginRight: "15px",
                }}
              />
            </a>
            <a href="https://www.linkedin.com/company/therisecollection" aria-label="linkedin-link">
              <FaLinkedin
                style={{ color: "white", height: "35px", width: "25px" }}
              />
            </a>
            <br></br>
            <br></br>
            <a href="/privacy-policy" style={{ color: 'white', borderBottom: 'solid 2px', borderColor: 'red', textDecoration: 'none', fontFamily: 'Roboto, sans-serif' }}>PRIVACY POLICY</a>
            <br></br>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default RedLightCamDetailPage
