import React from 'react'
import { Link } from "react-router-dom";
import { mediaMenu } from "./script/checkUnderline";




const AboutUsPage = () => {
  return (
    <div className="creative-portfolio" style={{ overflowX: 'scroll', minHeight: '100vh', background: '#0B0B0B' }}>
      {/* Start Header Area  */}
      <meta
        name="viewport"
        content="width=100, initial-scale=1, viewport-fit=cover, maximum-scale=1, user-scalable=0"
      />
      <div className="flex-box-header header">
        <div className="headerText">
          <a href="/">
            <h2>THE RISE COLLECTION</h2>
          </a>
        </div>
        <div className="menuItems">
          <Link to="/">
            <p id="homeMenuText">HOME</p>
          </Link>
          <Link to="/about-us">
            <p id="aboutMenuText" style={{ borderBottom: 'solid 2px', borderColor: 'red' }}>ABOUT US</p>
          </Link>
          <Link to="/#portfolio">
            <p id="gamesMenuText">PORTFOLIO</p>
          </Link>
          <Link to="/contact-us" onClick={mediaMenu}>
            <p id="mediaMenuText">CONTACT US</p>
          </Link>
        </div>
      </div>
      <h2 id="contactUsHeader" style={{ color: 'white', marginTop: '25vh', textAlign: 'center',  fontSize: '35px' }}>ABOUT US</h2>

      <p style={{ paddingLeft: '40px', paddingRight: '40px', color: 'white', display: 'block', marginBlockStart: '1em', marginBlockEnd: '1em', marginInlineStart: '2px', marginInlineEnd: '2px',  fontSize: '17px', textAlign: 'left', paddingTop: '5vh' }}>Founded in 2018, THE RISE COLLECTION is an internet-based software company. We produce and publish arcade-style games on multiple platforms. Our games are increasingly difficult and designed to be replayed.</p>
      <p style={{ paddingLeft: '40px',paddingRight: '40px', color: 'white', display: 'block', marginBlockStart: '1em', marginBlockEnd: '1em', marginInlineStart: '2px', marginInlineEnd: '2px',  fontSize: '17px', textAlign: 'left' }}>We have developed our software with a variety of game engines but our current favorite is Unity. At RISE we started with the mobile platforms, most of our current work can be found on both the Apple App Store and Google Play Store.</p>
      <p style={{ paddingLeft: '40px', paddingRight: '40px', color: 'white', display: 'block', marginBlockStart: '1em', marginBlockEnd: '1em', marginInlineStart: '2px', marginInlineEnd: '2px',  fontSize: '17px', textAlign: 'left', marginBottom: '15px' }}>View our team <a href="https://www.linkedin.com/company/therisecollection" style={{color: 'red'}}>here.</a></p>
    </div>
  )
}

export default AboutUsPage
