import React, { useEffect, useState } from 'react';
import './ShootingStar.css';
const ShootingStar = () => {
  const [position, setPosition] = useState({ top: '0%', left: '0%' });
  useEffect(() => {
    const randomizePosition = () => {
      const top = Math.random() * 100; // Random top position
      const left = Math.random() * 100; // Random left position
      setPosition({ top: `${top}%`, left: `${left}%` });
    };
    const interval = setInterval(() => {
      randomizePosition();
    }, 1000); // Change position every second
    return () => clearInterval(interval);
  }, []);
  return (
    <div className="shooting-star" style={{ top: position.top, left: position.left }} />
  );
};
export default ShootingStar;