
import React from 'react'
import './DetailPage.css'
import './PreviewPage.scss'
import { Link } from "react-router-dom";
import { FaLinkedin } from "@react-icons/all-files/fa/FaLinkedin";
import { FaFacebookSquare, FaYoutubeSquare, FaInstagramSquare } from "react-icons/fa";
import {
  homeClick,
  underlineMenuAbout,
  underlineMenuContact,
  underlineMenuPortfolio,
} from "./script/checkUnderline";
import './PreviewPage.scss'; // Import your SCSS file for styling
import { useEffect } from 'react';
import { FaSquareXTwitter } from "react-icons/fa6";
import { Helmet } from "react-helmet";

const iconImage = require("../src/script/test-img/portfolio-3.jpeg");
const appleImage = require("../src/script/test-img/apple.png");
const screenShot1 = require("../src/script/test-img/b-screen1.png");
const screenShot2 = require("../src/script/test-img/b-screen2.png");
const screenShot3 = require("../src/script/test-img/b-screen3.png");

export function useTitle(title) {
  useEffect(() => {
    const prevTitle = window.document.title
    window.document.title = title
    return () => {
      window.document.title = prevTitle
      console.log(`window title: ${window.document.title}`)
      document.getElementById("gamesMenuText").style.borderBottom = "solid 2px";
      document.getElementById("gamesMenuText").style.borderColor = "red";
    }

  })
}

const BaaridgeDetailPage = () => {
  useTitle("Baaridge - THE RISE COLLECTION")

  return (
    <div className="creative-portfolio" style={{ minHeight: '100vh', background: '#ffffff' }}>
      <Helmet>
        <title>Baaridge - THE RISE COLLECTION</title>
        <meta
          name="description"
          content=" Tap, Hold! Tap and Hold your finger on the screen to build a bridge for Mr Baaaa... The length of the bridge created depends on how long you Tap and Hold! Be mindful of the length of the bridge, Mr Baaa doesn't know any better and will run right off the edge!
          How long can you keep Mr Baaa's journey going?"
        />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <div className="flex-box-header header" id="detail">
        <div className="headerText">
          <a href="/">
            <h2 style={{ fontSize: "30px" }}>THE RISE COLLECTION</h2>
          </a>
        </div>
        <div className="menuItems">
        <Link to="/" onClick={homeClick}>
            <p id="homeMenuText">HOME</p>
          </Link>
          <Link to="/#about-us" onClick={underlineMenuAbout}>
            <p id="aboutUsMenuText">ABOUT US</p>
          </Link>
          <Link to="/#portfolio" onClick={underlineMenuPortfolio}>
            <p style={{ borderBottom: 'solid 2px', borderColor: 'red' }} id="gamesMenuText">PORTFOLIO</p>
          </Link>
          <Link to="/#contact-us" onClick={underlineMenuContact}>
            <p id="mediaMenuText">CONTACT US</p>
          </Link>
        </div>
      </div>

      <div className="app-container">
        <div className="game-info">
          <img src={iconImage} alt="Game Icon" className="game-icon" />
          <div className="game-details">
            <h2 style={{ fontSize: "18px" }}>BAARIDGE</h2>
            <p style={{ fontSize: '16px', color: 'red', fontWeight: 'bold' }}>THE RISE COLLECTION</p>
            <div className='tag-container'>
              <div className='right-tag' style={{ marginRight: '5px', background: 'black', textAlign: 'center', height: '20px', color: 'white', borderRadius: '5px', width: '125px', paddingTop: '0.98px' }}>MOBILE GAME</div>
              <div className='left-tag' style={{ background: 'blue', width: '35px', textAlign: 'center', height: '20px', color: 'white', borderRadius: '5px', paddingTop: '0.98px' }}>IOS</div>
            </div>
          </div>
        </div>
        <hr />
        <div style={{ marginBottom: '20px' }}>
          <h2>About the Mobile Game</h2>
          <p style={{ fontSize: '18px' }}>
          Tap and Hold! Press your finger on the screen to build a bridge for Mr. Baaa. The longer you hold, the longer the bridge! But be careful.. Mr. Baaa doesn’t know when to stop and will run right off the edge! How far can you keep his journey going?
          </p>
          <hr />
        </div>
        <h2>Media</h2>
        <div className="screenshot-container-web">
          <img src={screenShot1} alt='BAARIDGE_SCREENSHOT_1' />
          <img src={screenShot2} alt='BAARIDGE_SCREENSHOT_2' />
          <img src={screenShot3} alt='BAARIDGE_SCREENSHOT_3' />
        </div>
        <hr />
        <div style={{ fontSize: '18px' }}>
          <h2 style={{ fontSize: '24px' }}>Additional Information</h2>
          <ul style={{ fontSize: '18px' }}>
            <li>Release Date: July 16, 2021</li>
            <li>Developer: <a href="https://www.linkedin.com/company/therisecollection"
              style={{ color: "red", }}>THE RISE COLLECTION</a></li>

          </ul>
        </div>
        <hr />

        <h2>Download</h2>

        <div className="game-description">

          <a href='https://apps.apple.com/mm/app/baaridge/id1574222856'><img src={appleImage} style={{ width: '175px', marginLeft: '-10px' }} alt='BL1NK_DOWNLOAD_IOS' /></a>
        </div>
        <br></br>

      </div>

      <footer className="footer" id="detail-footer">
        <div className="footer-content">
          <div className="footer-social">
            <a href="https://www.instagram.com/rwtrcsc/" aria-label="instagram-link">
              <FaInstagramSquare
                style={{
                  color: "white",
                  height: "35px",
                  width: "25px",
                  marginRight: "15px",
                }}
              />
            </a>

            <a href="https://www.facebook.com/rwtrcsc/" aria-label="facebook-link">
              <FaFacebookSquare
                style={{
                  color: "white",
                  height: "35px",
                  width: "25px",
                  marginRight: "15px",
                }}
              />
            </a>
            <a href="https://twitter.com/rwtrcsc">
              <FaSquareXTwitter FaSquareXTwitter style={{
                color: "white",
                height: "35px",
                width: "25px",
                marginRight: "15px",
              }}></FaSquareXTwitter >

            </a>

            <a href="https://www.youtube.com/channel/UCKbCkAn_nig1WaweOc7kl7Q" aria-label="youtube-link">
              <FaYoutubeSquare
                style={{
                  color: "white",
                  height: "35px",
                  width: "25px",
                  marginRight: "15px",
                }}
              />
            </a>
            <a href="https://www.linkedin.com/company/therisecollection" aria-label="linkedin-link">
              <FaLinkedin
                style={{ color: "white", height: "35px", width: "25px" }}
              />
            </a>
            <br></br>
            <br></br>
            <a href="/privacy-policy" style={{ color: 'white', borderBottom: 'solid 2px', borderColor: 'red', textDecoration: 'none', fontFamily: 'Roboto, sans-serif' }}>PRIVACY POLICY</a>
            <br></br>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default BaaridgeDetailPage
